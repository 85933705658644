import React, {useEffect, useState, useRef, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {AuthContext} from "./AuthContext";
import KlantOverzichtInstallaties from "./KlantOverzichtInstallaties";
import ProjectOverzicht from "./ProjectOverzicht";
import DataParser from "../helpers/DataParser";

function Projecten () {
    const pass = useContext(AuthContext).auth.pass;
    const verboUser = useContext(AuthContext).auth.verboUser;
    const params = useParams();
    const navigate = useNavigate();
    const inputRef = useRef();
    const [projecten, setProjecten] = useState(() => {
        return JSON.parse(localStorage.getItem("projecten"));
    });
    const [klanten, setKlanten] = useState(() => {
        return JSON.parse(localStorage.getItem("klanten"));
    });
    const [zoekwaarde, setZoekwaarde] = useState(() => {
        if (params.zoekwaarde === undefined) return "";
        return params.zoekwaarde
    });
    const [networkLoaded, setNetworkLoaded] = useState(false);
    const [networkKlantenLoaded, setNetworkKlantenLoaded] = useState(false);

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'projecten');
        formData.append('pass', pass);

        inputRef.current.focus();

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                let projectenData = DataParser(data.data);
                setProjecten(projectenData);
                localStorage.setItem("projecten", JSON.stringify(projectenData));
                setProjecten(projectenData);
                setNetworkLoaded(true);
            })
            .catch(error => console.log(error))
            .finally(() => {
                //setProjecten(() => setFilterWaarde());
            });
    }, []);

    useEffect(() => {
        const filter = zoekwaarde;

        if (filter !== undefined) navigate(`/projecten/${filter}`, { replace: true });

        setProjecten(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [zoekwaarde]);

    useEffect(() => {
        setProjecten(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [networkLoaded]);

    useEffect(() => {
        setProjecten(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [networkKlantenLoaded]);

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'klanten');
        formData.append('pass', pass);

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                let klantenData = DataParser(data.data);
                setKlanten(klantenData);
                localStorage.setItem("klanten", JSON.stringify(klantenData));
                setKlanten(klantenData);
                setNetworkKlantenLoaded(true);
            })
            .catch(error => console.log(error))
            .finally(() => {
                //setProjecten(() => setFilterWaarde());
            });
    }, []);

    function setFilterWaarde(data = projecten) {

        if (!data) return [];

        let _zoekwaarde = zoekwaarde.trim();

        const updatedInstallaties = data.map((project) => {
            let filterWaarde = 0;
            project.filterWaarde = 0;

            // Volledig zoekveld
            filterWaarde += getSpecifiekeWaarde(project.rel_name, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.rel_street, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.rel_zipcode, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.rel_city, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.projStreet, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.projZipCode, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.projCity, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.projName, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.rel_contactname, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.contact_name, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(project.id, _zoekwaarde, 10);

            zoekwaarde.split(" ").forEach(filter => {
                if (filter === "") return;
                filterWaarde += getSpecifiekeWaarde(project.contract_type_txt, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.rel_name, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.rel_street, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.rel_zipcode, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.rel_city, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.projStreet, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.projZipCode, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.projCity, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.projName, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.rel_contactname, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.contact_name, filter, 1);
                filterWaarde += getSpecifiekeWaarde(project.id, filter, 1);
            });

            // TelefoonNummer nakijken
            filterWaarde += getTelefoonfilterwaarde(project.phones);

            project.filterWaarde = filterWaarde;
            return project;
        });

        return updatedInstallaties;
    }

    function getKlantenMatches(data = klanten) {
        if (!data) return [];

        const updatedklanten = data.map((klant) => {
            let filterWaarde = 0;
            klant.filterWaarde = 0;

            // TelefoonNummer nakijken
            filterWaarde += getTelefoonfilterwaarde(klant.phones);

            klant.filterWaarde = filterWaarde;
            return klant;
        });

        return updatedklanten;
    }

    function getSpecifiekeWaarde(zoekveld, waarde, multiplier = 1) {
        if (waarde === "") return 0;

        if (zoekveld && zoekveld.toString().toLowerCase() === waarde.toString().toLowerCase()) {
            return 10 * multiplier;
        } else if (zoekveld && zoekveld.toString().toLowerCase().startsWith(waarde.toString().toLowerCase())) {
            return 5 * multiplier;
        } else if (zoekveld && zoekveld.toString().toLowerCase().includes(waarde.toString().toLowerCase())) {
            return 1 * multiplier;
        }
        return 0;
    }

    function getTelefoonfilterwaarde(phones) {
        const regex = /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(56|60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/;
        const zoekTelefoonRegEx = regex.exec(zoekwaarde.replaceAll(" ",""));
        if (zoekTelefoonRegEx === null) return 0;

        let tempZoekwaarde = 0;

        Object.values(phones).forEach(phone => {
            const tempPhone = regex.exec(phone);
            if (tempPhone !== null && tempPhone[4] !== null && tempPhone[4] === zoekTelefoonRegEx[4]) tempZoekwaarde = 10000;
        })

        return tempZoekwaarde;
    }

    function filterFunctie (installatie) {
        if (projecten != null && projecten.length < 10) return true;
        if (zoekwaarde != null && zoekwaarde.length < 3) {
            return false;
        }
        return (installatie.filterWaarde > 0);
    }

    function filterFunctieKlanten (klant) {
        if (zoekwaarde != null && zoekwaarde.length < 3) {
            return false;
        }
        return (klant.filterWaarde > 0);
    }

    function getEmptyState() {
        if (projecten != null && projecten.length < 10) return;

        if ((zoekwaarde != null && zoekwaarde.length < 3)) {
            return (
                <div className={`emptystate`}><div>Zoek een project door minstens 3 karakters in te vullen</div></div>
            );
        }

        return;
    }

    return (<div className="installaties">
        <input ref={inputRef} id="installatieZoekveld" autoComplete="off" value={zoekwaarde} onChange={event => setZoekwaarde(event.target.value)} placeholder="Zoeken..." />
        <main>
            {klanten && verboUser && klanten.filter(filterFunctieKlanten).map(klant => {
                return (<KlantOverzichtInstallaties key={klant.id} details={klant} verboUser={verboUser} />);
            })}
              {projecten && projecten.filter(filterFunctie).sort((a, b) => a.filterWaarde > b.filterWaarde ? -1 : 1).map(project => {
                  return (<ProjectOverzicht key={project.id} details={project} verboUser={verboUser} />)
              })}
            {getEmptyState()}
        </main>
    </div>);
}

export default Projecten;