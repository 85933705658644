import React, {useContext, useState} from "react";
import Directory from "./Directory";
import {SettingsContext} from "./SettingsContext";

export default function InstallatieOwncloud (props) {
    const pad = props.pad.replaceAll('\\','/');
    const settings = useContext(SettingsContext).settings;
    const [filterPDF, setFilterPDF] = useState((settings.onlyImgPdf===null||settings.onlyImgPdf!==false));
    const [filterIMG, setFilterIMG] = useState((settings.onlyImgPdf===null||settings.onlyImgPdf!==false));

    function handleFilterPDFChange (e) {
        setFilterPDF(!filterPDF);
    }

    function handleFilterIMGChange (e) {
        setFilterIMG(!filterIMG);
    }

    function listExtensionsToFilter() {
        let extensions = [];

        if (filterPDF) extensions.push('pdf');
        if (filterIMG) {
            extensions.push('jpg');
            extensions.push('jpeg');
            extensions.push('png');
            extensions.push('gif');
            extensions.push('tiff');
            extensions.push('svg');
            extensions.push('bmp');
        }

        return extensions;
    }

    return (
        <div className="owncloud-wrapper">
            <section className="filter">
                <form onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="pdfCheck" className="checkcontainer"><input type="checkbox" value="pdf" id="pdfCheck" checked={filterPDF} onChange={handleFilterPDFChange} />PDF<span
                        className="checkmark"></span></label>
                    <label htmlFor="imgCheck" className="checkcontainer"><input type="checkbox" value="img" id="imgCheck" checked={filterIMG} onChange={handleFilterIMGChange} />Foto's<span
                        className="checkmark"></span></label>
                </form>
            </section>
            <Directory pad={'/Verbo/'+pad} owncloud={true} title={pad} extensions={listExtensionsToFilter()} imgHandler={props.imgHandler} />
        </div>
    );
}