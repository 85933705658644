import React from "react";
import {Link} from "react-router-dom";

function ProjectOverzicht (props) {
    const details = props.details;
    const {id, descr, projStreet, projZipCode, projCity, projName} = details;
    const reloadDocument = props.reloadvar;

    let classNametext = 'project';
    classNametext += (details.fl_active == "1")?'':' niet-actief';

    return (
        <Link to={`/project/${id}`} className={classNametext} reloadDocument={reloadDocument}>
            <div className="algemeneInfo">
                <b>{id} - {descr}</b>
            </div>
            <div className="adressen">
                <div className="installatieAdres adres">
                    <div className="naam">{projName}</div>
                    <div className="adres">
                        <div className="straat">{projStreet}</div>
                        <div className="postcode-gemeente">{projZipCode} {projCity}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default ProjectOverzicht;