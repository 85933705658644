import React from "react";
import ProjectOverzicht from "./ProjectOverzicht";

export default function ProjectGerelateerd(props) {
    const projecten = props.projecten;
    const verboUser = props.verboUser;

    return (
        <div className="installaties">
            {projecten && projecten.map(project => {
                return (<ProjectOverzicht key={project.id} details={project} verboUser={verboUser} reloadvar={true} />)
            })}
        </div>
    );
}