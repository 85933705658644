import React from "react";

export default function FileInput(props) {

    return (
        <div className="fileinput">
            <label>Foto's/bestanden meesturen?</label>
            <label className="custom-file-upload">
                <input type="file" multiple onChange={props.onFilesChange} />
                <i className="fa fa-cloud-upload" /> Bestanden toevoegen
            </label>
            <div className="files-preview">
                {props.files.map(x =>
                    <div key={x.name} className="file-preview" onClick={props.onFilesRemove.bind(this, x)}><i
                        className="fa fa-trash-can"></i> {x.name} {x.type.includes("image") && <img src={URL.createObjectURL(x)}/>}</div>
                )}
            </div>
        </div>
    );
}