import React, {createRef, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import FileInput from "./FileInput";
import {AuthContext} from "./AuthContext";
import InvertentieComfirmation from "../helpers/InvertentieComfirmation";

/**
 * Sends an email with form data to a specified endpoint.
 *
 * @param {Object} props - The props object containing the necessary data.
 * @param {Object} props.installatie - The installation data object.
 * @returns {JSX.Element} - The JSX element for the mail form.
 */
export default function MailForm(props) {
    const navigate = useNavigate();
    const auth = useContext(AuthContext).auth;
    const installatie = props.installatie;
    const [formSubmit, setFormSubmit] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const fileInput = createRef();
    const [error, setError] = useState("");

    function handleSubmit (event) {
        event.preventDefault();
        setFormSubmit(true);
    }

    useEffect(() => {
        if (formSubmit) {
            setLoading(true);
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`files[${i}]`, files[i]);
                // formData.append(`filess[${i}]`, fileInput.current.files[i]);
            }
            formData.append('pass', auth.pass);
            formData.append('text_html', composeHTMLMail());
            formData.append('text_plain', composePlainMail());
            formData.append('subject', `Interventie aanvraag -  ${installatie.id} - ${installatie.projName}`);

            axios({
                method: "post",
                url: "https://technician.verbo.be/sendmail.php",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(data => {
                    if (data.data.error == undefined) {
                        setError("");
                    } else {
                        setError(data.data.error);
                    }
                })
                .then(() => {
                    if (auth.verboUser === false) {
                        let formData = new FormData();
                        for (let i = 0; i < files.length; i++) {
                            formData.append(`files[${i}]`, files[i]);
                            // formData.append(`filess[${i}]`, fileInput.current.files[i]);
                        }
                        formData.append('text_html', composeHTMLMailConfirmation());
                        formData.append('text_plain', composePlainMailConfirmation());
                        formData.append('subject', `Interventie aanvraag -  ${installatie.id} - ${installatie.projName}`);
                        formData.append('to', auth.email);

                        axios({
                            method: "post",
                            url: "https://technician.verbo.be/sendmail.php",
                            data: formData,
                            headers: {"Content-Type": "multipart/form-data"},
                        })
                            .then(data => {
                                if (data.data.error == undefined) {
                                    setError("");
                                    navigate(`/installatie/${installatie.id}`);
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                })
                .catch(error => {
                    console.log(error);
                    setError(error.toString());
                })
                .finally(() => {
                    setLoading(false);
                    setFormSubmit(false);
                    navigate(`/installatie/${installatie.id}`);
                });
        }
    }, [formSubmit]);

    function composeHTMLMail() {
        let html = "";

        html += `<h1>Aanpassen aan <a href="https://verbotech.verbo.be/installatie/${installatie.id}">${installatie.id} - ${installatie.projName} - ${installatie.contract_type_txt}</a></h1>`;
        html += `<div>Klant: ${installatie.rel_name}</div><hr/>`;

        if (auth.user !== '') {
            html += `<div>Aanvrager: ${auth.user}</div>`;
        }
        if (textArea !== '') {
            html += `<div>bijkomende informatie: ${textArea}</div>`;
        }

        return `<div>${html}</div>`;
    }

    function composeHTMLMailConfirmation() {
        return InvertentieComfirmation();
    }
    
    function composePlainMail() {
        let plain = `Aanpassen aan ${installatie.id} - ${installatie.projName}\r\nKlant: ${installatie.rel_name}`;

        if (auth.user !== '') {
            plain += `Verbotist: ${auth.user}\r\n`;
        }
        if (textArea !== '') {
            plain += `bijkomende informatie: ${textArea}\r\n`;
        }

        return `${plain}`;
    }

    function composePlainMailConfirmation() {
        let plain = "";

        plain += "Bedankt voor uw interventie aanvraag\r\n";
        plain += "Uw aanvraag wordt tijdens kantooruren verwerkt."

        return plain;
    }

    function handleFileChange(e) {
        var tempFiles = e.target.files;
        var filesArr = Array.prototype.slice.call(tempFiles);
        setFiles([...files, ...filesArr]);
    }

    function removeFile(f) {
        setFiles(files.filter(x => x !== f) );
    }

    if (loading) {
        return (
            <div className="loading">Laden...</div>
        );
    }

    return (<>
        {(loading === false) && (
        <div className={`mail-form`}>
            <form onSubmit={handleSubmit}>
                {error !== "" &&
                    <div className="error">Er is iets mis gegaan. {error}</div>
                }
                <textarea value={textArea} onChange={(e) => setTextArea(e.target.value)}
                          placeholder="Bijkomende informatie"/>
                {/*<label htmlFor="fileUpload">Foto's meesturen?</label>*/}
                {/*<input id="fileUpload" type="file" accept="image/*" ref={fileInput} multiple/>*/}
                <FileInput files={files} onFilesChange={handleFileChange} onFilesRemove={removeFile} />
                <input type="submit" value="Versturen"/>
            </form>
        </div>
        )}</>
    );
};