import React, {useContext} from "react";
import {SettingsContext} from "./SettingsContext";

function MapsURL (props) {
    const settings = useContext(SettingsContext).settings;

    let gmapsURL = "https://www.google.com/maps/search/?api=1&query="+encodeURIComponent(props.street+", "+props.zipCode+" "+props.city);
    let wazeURL = "https://waze.com/ul?navigate=yes&q="+encodeURIComponent(props.street+", "+props.zipCode+" "+props.city);
    let appleMapsURL = "http://maps.apple.com/?dirflg=d&daddr="+encodeURIComponent(props.street+", "+props.zipCode+" "+props.city);
    let mapsUrl = "";

    switch (settings.maps) {
        case 1:
            mapsUrl = wazeURL;
            break;
        case 2:
            mapsUrl = appleMapsURL;
            break;
        case 0:
        default:
            mapsUrl = gmapsURL;
            break;
    }

    return (
        <a href={mapsUrl} target="_blank"><i className="fa-solid fa-map-location"></i></a>
    );
}

export default MapsURL;