import React from "react";
import {DateConvert} from "../helpers";

export default function KlantDossiers(props) {
    return (
        <div className="dossiers">
            {props.dossiers && props.dossiers.map(dossier => {return (
                <div key={dossier.id} className="dossier">
                    <h4 className="id">{dossier.id} - {dossier.descr}</h4>
                </div>
            )})}
        </div>
    );
}