import React from "react";
import {Link} from "react-router-dom";
import InstallatieOverzicht from "./InstallatieOverzicht";
import KlantOverzichtInstallaties from "./KlantOverzichtInstallaties";

function KlantInstallaties (props) {
    const installaties = props.installaties;
    const verboUser = props.verboUser;

    return (
        <div className="installaties">
            {installaties && installaties.map(installatie => {
                return (<InstallatieOverzicht key={installatie.id} details={installatie} verboUser={verboUser} />)
            })}
        </div>
    );
}

export default KlantInstallaties;