import React from "react";
import {Link} from "react-router-dom";

function InstallatieOverzicht (props) {
    const details = props.details;
    const verboUser = props.verboUser;
    const {id, filterWaarde, contract_type_txt, rel_name, rel_street, rel_zipcode, rel_city, projStreet, projZipCode, projCity, projName} = details;

    return (
        <Link to={`/installatie/${id}`} className="installatieOverzicht">
            <div className="algemeneInfo">
                {id} - {contract_type_txt}
            </div>
            {verboUser && details.tickettenlijst !== null && <div className="ticket-alert"><Link to={`/installatie/${id}/ticketten`}>Open ticketten</Link></div>}
            <div className="adressen">
                <div className="klantAdres adres">
                    <label>Facturatieadres:</label>
                    <div className="naam">{rel_name}</div>
                    <div className="adres">
                        <div className="straat">{rel_street}</div>
                        <div className="postcode-gemeente">{rel_zipcode} {rel_city}</div>
                    </div>
                </div>
                <div className="installatieAdres adres">
                    <label>installatieadres:</label>
                    <div className="naam">{projName}</div>
                    <div className="adres">
                        <div className="straat">{projStreet}</div>
                        <div className="postcode-gemeente">{projZipCode} {projCity}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default InstallatieOverzicht;