import React, {useContext, useEffect, useState} from "react";
import {createClient} from "webdav/web";
import {AuthContext} from "./AuthContext";
import {SettingsContext} from "./SettingsContext";
import axios from "axios";
import Loading from "./Loading";
import File from "./File";

export default function Directory (props) {
    const owncloudOptions = useContext(AuthContext).auth.OwnCloud;
    const settings = useContext(SettingsContext).settings;
    const pad = props.pad;
    const owncloudDossier = props.owncloud;
    const imgHandler = props.imgHandler;
    const extensions = props.extensions;
    const depth = (props.depth == null)?1:props.depth;
    const title = (props.title == null)?pad:props.title;
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const baseFileURL = "https://cloud.verbo.be:4433/remote.php/webdav";
    const [hiddenFiles, setHiddenFiles] = useState(false);
    const [open, setOpen] = useState(() => (depth < 3));
    const [loadingDoc, setLoadingDoc] = useState(false);

    useEffect(() => {
        setLoading(true);
        const client = createClient(
            owncloudOptions.url,
            {
                username: owncloudOptions.user,
                password: owncloudOptions.pass
            }
        );

        client.getDirectoryContents(pad)
            .then ((data) => {
                setFiles(data);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <div className="directory">
                Laden...
            </div>
        );
    }

    function handleDirectoryClick() {
        setOpen(!open);
    }

    function downloadDoc (doc){
        if (loadingDoc) return null;

        setLoadingDoc(true);

        if (doc.indexOf("/Verbo/") === 0) doc = doc.substring(7);

        let formData = new FormData();
        formData.append('action', 'owncloud');
        formData.append('doc', doc);
        formData.append('owncloud', (owncloudDossier)?1:0);
        formData.append('pass', props.pass);

        var docURL = "";

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                // base64 string
                let base64str = data.data.encodedString;
                let type = data.data.typeString;

// decode base64 string, remove space for IE compatibility
                let binary = atob(base64str.replace(/\s/g, ''));
                let len = binary.length;
                let buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

// create the blob object with content-type "application/pdf"
                let blob = new Blob( [view], { type: type });
                let url = URL.createObjectURL(blob);
                // window.open(url, '_self');
                if (type.includes("image")) {
                    imgHandler(url);
                } else {
                    let elm = document.createElement('a');
                    elm.href = url;
                    let filename = doc.substring(doc.lastIndexOf("/"));
                    elm.setAttribute('download', filename); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
                    elm.click();
                }
            })
            .catch(error => console.log(error))
            .finally(() => setLoadingDoc(false));

        return docURL;
    }

    let classNames = 'directory';
    if (hiddenFiles) classNames += " hiddenFiles";

    let classNamesWrapper = "directory-wrapper";
    if (open) classNamesWrapper += " open";
    else classNamesWrapper += " close";

    return (<div className={classNamesWrapper}>
        <div className={`directory-title`} onClick={handleDirectoryClick}>
            <i className="fa-solid fa-folder"></i> {title}
            <i className="fa-solid fa-chevron-up open-close-clicker"></i></div>
        <div className={classNames}>
            {files && files.map((file, index) => {
                if (file.basename == pad) return;
                if (file.type === "file") {
                    if (settings.hideDWG !== null && settings.hideDWG === true) {
                        const regex = /(\.dwg|\.dxf)$/g;
                        if (file.basename.toLowerCase().match(regex)) {
                            if (hiddenFiles !== true) setHiddenFiles(true);
                            return;
                        }
                    }
                    if (extensions !== undefined && extensions.length > 0) {
                        const regex = new RegExp('(\.'+extensions.join('|\.')+')$');
                        if (file.basename.toLowerCase().match(regex) === null) {
                            if (hiddenFiles !== true) setHiddenFiles(true);
                            return;
                        }
                    }
                    return (
                        <File key={index} imgHandler={props.imgHandler} owncloud={owncloudDossier} file={file} />
                    );
                } else if (file.type === "directory") {
                    return (
                        <Directory key={index} owncloud={owncloudDossier} title={file.basename} pad={pad + "/" + file.basename} extensions={extensions} depth={depth+1} imgHandler={props.imgHandler} />
                    );
                }
            })}
        </div>
    </div>);
}