import React from "react";
import {isNull} from "util";
import {Link} from "react-router-dom";
import ProjectOverzicht from "./ProjectOverzicht";
import ProjectGerelateerd from "./ProjectGerelateerd";

export default function ProjectInfo (props) {
    const project = props.project;
    const verboUser = props.verboUser

    return (
        <div className="installatieInfo">
            <div className={`contactInfo`}>
                <h3>Contactpersoon</h3>
                {project.contacten && project.contacten.map((contact, index) => {
                    return (
                        <div className="contact" key={index}>
                            <div className="naam">{contact.name !== "0" && contact.name}</div>
                            <div className="tel"><a href={`tel:${contact.mobile}`}>{contact.mobile}</a></div>
                            <div className="mail"><a href={`mailto:${contact.email}`}>{contact.email}</a></div>
                        </div>
                    );
                })}
            </div>
            <div className="extraVelden">
                <div className="fabrikant">Owncloud: {project.param1}</div>
            </div>
            <div className="gerelateerdeProjecten">
                {project.relatedProjects !== undefined && project.relatedProjects != null && <ProjectGerelateerd projecten={project.relatedProjects} verboUser={verboUser} />}
            </div>
        </div>
    );
}