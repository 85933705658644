import './App.css';
import React, {useContext, useEffect, useState} from "react";
import {Routes, NavLink, Route, Link, useLocation, useNavigate} from "react-router-dom";
import Installaties from "./componenten/installaties";
import Installatie from "./componenten/Installatie";
import Home from "./componenten/Home";
import Login from "./componenten/Login";
import {AuthContext} from "./componenten/AuthContext";
import Settings from "./componenten/Settings";
import Klant from "./componenten/Klant";
import Projecten from "./componenten/projecten";
import Project from "./componenten/Project";
import Modal from "./componenten/Modal";
import LoginConfirm from "./componenten/LoginConfirm";
import Partners from "./componenten/Partners";

function App() {
    const authC = useContext(AuthContext);
    let navigate = useNavigate();
    let location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(true);
    const [img, setImg] = useState("");

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    const openModal = (e) => {
        setImg(e);
        setIsModalOpen(false);
    }

    useEffect(() => {
        if (authC.auth === null && location.pathname.startsWith("/login") === false) {
            navigate("/login");
        }
    }, );

    useEffect(() => {
        if (location.pathname.startsWith("/installatie") === false)
            document.title = `Verbotech`;
    }, [location]);

    return (
        <div className="App">
            <header>
                <Link to={`/`}><img className={`logo`} src={`/LogoVerboQuadrigroot.png`} /></Link>
                <nav>
                    <NavLink to={"/"}>Start</NavLink>
                    <NavLink to={"/installaties"}>Alle installaties</NavLink>
                    {(authC.auth != null) && (authC.auth.verboUser != null) && (authC.auth.verboUser === true) && <NavLink to={"/instellingen"}><i className="fa fa-gear"></i></NavLink>}
                </nav>
            </header>

            {(authC.auth !== null || location.pathname.startsWith("/login")) &&
                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path={'/login'} element={<Login/>}/>
                    <Route path={'/login/confirm'} element={<LoginConfirm/>}/>
                    <Route path={`/installaties`} element={<Installaties/>}/>
                    <Route path={`/installaties/:zoekwaarde`} element={<Installaties/>}/>
                    <Route path={`/installatie/:id/*`} element={<Installatie imgHandler={openModal}/>}/>
                    <Route path={`/projecten`} element={<Projecten/>}/>
                    <Route path={`/projecten/:zoekwaarde`} element={<Projecten/>}/>
                    <Route path={`/project/:id/*`} element={<Project imgHandler={openModal}/>}/>
                    <Route path={`/klanten`} element={<Installaties/>}/>
                    <Route path={`/klanten/:id/*`} element={<Klant/>}/>
                    <Route path={`/partners`} element={<Partners/>}/>
                    <Route path={`/instellingen`} element={<Settings/>}/>
                    <Route path={`/not-found/:id`} element={<div><h1>Installatie niet gevonden</h1></div>}/>
                </Routes>
            }

            <Modal isOpen={isModalOpen} toggleModal={toggleModal} closeOnOutsideClick={true}><img src={img}/></Modal>
        </div>
    );
}

export default App;
