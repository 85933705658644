import React, {useState} from "react";
import {isNull} from "util";
import {Link} from "react-router-dom";
import axios from "axios";

export default function InstallatieInfo (props) {
    const installatie = props.installatie;
    const [loadingDoc, setLoadingDoc] = useState(false);

    function getGWP () {
        let freon = installatie.param2.toLowerCase();
        switch (freon) {
            case "r32":
                return 675;
                break;
            case "r410a":
                return 2088;
                break;
            case "r134a":
                return 1430;
                break;
            case "r404a":
                return 3922;
                break;
            case "r449a":
                return 1397;
                break;
            case "r407c":
                return 1774;
                break;
            case "r407f":
                return 1825;
                break;
            case "r507":
                return 3985;
                break;
            case "r600":
                return 4;
                break;
            case "r744":
                return 1;
                break;
            default:
                return 0;
        }
    }

    function getTotaleInhoud() {
        try {
            let strInhoud = installatie.param3.slice(0,-2);
            if (strInhoud === "") return "";
            strInhoud = strInhoud.replaceAll(",",".");
            let inhoud = eval(strInhoud);
            return inhoud;
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    function getTotaleInhoudString() {
        let totaleInhoud = getTotaleInhoud();

        if (totaleInhoud === "") return "";

        if (typeof totaleInhoud === 'number') {
            totaleInhoud = Math.round((parseFloat(totaleInhoud) + Number.EPSILON) * 100) / 100;
        }

        const totaleInhoudString = totaleInhoud.toString().replaceAll('.',',');
        return totaleInhoudString + " kg";
    }

    function getLekcontroles () {
        const GWP = getGWP();
        const inhoud = getTotaleInhoud();
        const co2eq = GWP * inhoud;

        if (co2eq > 50000) {
            return "Elke 6 maanden";
        } else if (co2eq > 5000) {
            return "Elke 12 maanden";
        } else {
            return "Geen";
        }
    }

    function isKoelinstallatie() {
        const pattern = /nvt|n\.v\.t\.|na|n\.a\./i;
        return installatie.param2 === "" || pattern.test(installatie.param2) === false;
    }

    function downloadWerkbon (docID){
        if (loadingDoc) return null;

        setLoadingDoc(true);

        let formData = new FormData();
        formData.append('action', 'download');
        formData.append('werkbon', docID);
        formData.append('pass', props.pass);

        var docURL = "";

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                // base64 string
                let base64str = data.data;

// decode base64 string, remove space for IE compatibility
                let binary = atob(base64str.replace(/\s/g, ''));
                let len = binary.length;
                let buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

// create the blob object with content-type "application/pdf"
                let blob = new Blob( [view], { type: "application/pdf" });
                let url = URL.createObjectURL(blob);
                window.open(url, '_self');

            })
            .catch(error => console.log(error))
            .finally(() => setLoadingDoc(false));

        return docURL;
    }

    return (
        <div className="installatieInfo">
            <div className="extraVelden">
                <h3>Gegevens</h3>
                <div className="fabrikant">Fabrikant: {installatie.param1}</div>
                {isKoelinstallatie() &&
                    <>
                        <div className="freon">Freon: {installatie.param2}</div>
                        <div className="GWP">GWP: {getGWP()}</div>
                        <div className="inhoud">Freon inhoud: {installatie.param3}</div>
                        <div className="totaleInhoud">Totale inhoud: {getTotaleInhoudString()}</div>
                        <div
                            className="totaleInhoud">CO²-equivalent: {Math.round((parseFloat(getTotaleInhoud() * getGWP()) + Number.EPSILON) * 1) / 1000} t
                        </div>
                        <div className="lekcontroles">Verplichte lekcontroles: {getLekcontroles()}</div>
                    </>}
                {(installatie.param4 == "") ? "" : <div className="Olie">Olie: {installatie.param4}</div>}
                {(installatie.param5 == "") ? "" : <div className="zekering">Zekering: {installatie.param5}</div>}
            </div>
            <div className={`certificaten`}>
                <h3>Certificaten</h3>

                {installatie.lastLektest !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastLektest + " LC")
                }}><i className="fa-solid fa-file"></i> Lektestcertificaat</a></div>}

                {installatie.lastOnderhoud !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastOnderhoud + " OC")
                }}><i className="fa-solid fa-file"></i> Onderhoudscertificaat</a></div>}

                {installatie.lastOpstart !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastOpstart + " OR")
                }}><i className="fa-solid fa-file"></i> Opstartrapport</a></div>}

                {installatie.lastDruktest !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastDruktest + " DC")
                }}><i className="fa-solid fa-file"></i> Druktest</a></div>}

                {installatie.lastRecuperatie !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastRecuperatie + " RC")
                }}><i className="fa-solid fa-file"></i> Recuperatiecertificaat</a></div>}

                {installatie.lastVentilatieonderhoud !== null && <div className={'certificaat'}><a href="#" onClick={(event) => {
                    event.preventDefault();
                    downloadWerkbon(installatie.lastVentilatieonderhoud + " VC")
                }}><i className="fa-solid fa-file"></i> Ventilatieonderhoudscertificaat</a></div>}
            </div>
            {isKoelinstallatie() &&
                <div className="lekverliezen">
                    <h3>Lekverliezen</h3>
                    {installatie.lekverliezen && installatie.lekverliezen.sort(function (a, b) {
                        return b.YEAR - a.YEAR
                    }).map(lekverlies => {
                        return (
                            <div className="lekverlies"
                                 key={lekverlies.YEAR}>{lekverlies.YEAR}: {Math.round((parseFloat(lekverlies.somlekverlies) + Number.EPSILON) * 100) / 100} kg{getTotaleInhoud() !== "" && getTotaleInhoud() !== 0 && lekverlies.somlekverlies !== 0 &&
                                <span> &nbsp;-&nbsp; {Math.round((parseFloat(lekverlies.somlekverlies / getTotaleInhoud()) + Number.EPSILON) * 100)}% &nbsp;-&nbsp; ({Math.round((parseFloat(lekverlies.somlekverlies * getGWP()) + Number.EPSILON) * 1) / 1000} t CO²)</span>}</div>
                        );
                    })}
                    {/*<div className="totale-lekverlies">Totale lekverlies: {installatie.totalelekverliezen}</div>*/}
                </div>}
            {installatie.components && installatie.components.length !== 0 &&
                <div className="componenten">
                    <h3>Componenten</h3>
                    {installatie.components && installatie.components.map((component, index) => {
                        return (
                            <div className="component" key={index}>
                                <div className="art">{component.call_id_child}</div>
                                <div
                                    className="omschrijving">{component.descr_child} {(component.path != null) && (component.path != "") &&
                                    <span> - <a href={component.path} target="_blank"><i
                                        className="fa-solid fa-file-pdf"></i> Handleiding</a></span>}</div>
                                <div
                                    className="hoeveelheid">{Math.round((parseFloat(component.quantity) + Number.EPSILON) * 100) / 100}</div>
                            </div>
                        );
                    })}
                </div>}
        </div>
    );
}