import React from "react";
import {Link} from "react-router-dom";

function KlantOverzichtInstallaties (props) {
    const details = props.details;
    const verboUser = props.verboUser;
    const {id, name} = details;

    return (
        <Link to={`/klanten/${id}`} className="klantOverzichtInstallatie">
            <div className="algemeneInfo">
                <span>Dit telefoon nummer is ook gekend voor</span>
                <h3>{name}</h3>
            </div>
        </Link>
    );
}

export default KlantOverzichtInstallaties;