import React, {useContext, useEffect, useState} from "react";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";
import {AuthContext} from "./AuthContext";

export default function LoginConfirm () {
    const [code, setCode] = useState();
    const [loading, setLoading] = useState(false);
    const [loginSubmit, setLoginSubmit] = useState(false)
    const [validation, setValidation] = useState(false);
    const authC = useContext(AuthContext);
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    function handleSubmitLoginExternal (e) {
        e.preventDefault();
        setLoginSubmit(true);
    }

    useEffect(() => {
        if (searchParams.get("code") !== null) {
            setCode(searchParams.get("code"));
        }
    }, [searchParams]);

    useEffect(() => {
        if (loginSubmit) {
            setLoading(true);
            let formData = new FormData();
            formData.append('code', code);

            axios({
                method: "post",
                url: "https://technician.verbo.be/ldap.php",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(data => {
                    setValidation(false);
                    if (data.data.error === undefined) {
                        if (data.data.status == 1) {
                            if (data.data.OwnCloud != null && data.data.OwnCloud.length != 0) {
                                data.data.verboUser = true
                            } else {
                                data.data.verboUser = false;
                            }
                            localStorage.setItem("auth", JSON.stringify(data.data));
                            authC.setAuth(data.data);
                            navigate("/");
                        } else {
                            setValidation(true);
                            authC.setAuth(null);
                        }
                    } else  {
                        setValidation(true);
                        authC.setAuth(null);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loginSubmit]);

    useEffect(() => {
        if (loading === false)
            setLoginSubmit(false);
    }, [loading]);

    return (
        <>
            {!loading &&
                <div className="usertype-container">
                    <main className="login-container">
                        <div className="login confirm">
                            <h2>Login Confirm</h2>
                            <p>Een e-mail werd gestuurd naar u.</p>
                            <p>Klik op de link in de e-mail om meteen toegang te krijgen of vul hieronder de 6-cijferige
                                code in die u in de mail terugvindt.</p>
                            <form action="#" className="loginform" onSubmit={handleSubmitLoginExternal}>
                                <input type="text" placeholder="Login code" value={code}
                                       onChange={event => setCode(event.target.value)}/>
                                <input type={"submit"} value="Aanmelden"/>
                                {validation &&
                                    <div className="validation">De code klopt niet, of is vervallen.<br/><NavLink
                                        to={"/login"}>Nieuwe code aanvragen</NavLink></div>}
                            </form>
                            <p><NavLink to="/login">Geen mail ontvangen?</NavLink></p>
                        </div>
                    </main>
                </div>
            }
        </>
    );
}