import React, {useContext} from "react";
import {SettingsContext} from "./SettingsContext";

export default function Settings () {
    const settings = useContext(SettingsContext).settings;
    const changeSettings = useContext(SettingsContext).setSettings;

    function changeSetting(key, value) {
        changeSettings({...settings, [key]: value});
    }

    function logout() {
        localStorage.clear();
    }

    return (
        <div className='settings'>
            <form onSubmit={(e) => e.preventDefault()}>
                <h1>Instellingen</h1>
                <div className="setting">
                    <input type="checkbox" id={`hideDWG`} checked={settings.hideDWG} onChange={(e) => changeSetting('hideDWG', e.target.checked)}/>
                    <label htmlFor={`hideDWG`}>Verberg DWG bestanden die in de Owncloud map staan</label>
                </div>
                <div className="setting">
                    <input type="checkbox" id={`onlyImgPdf`} checked={settings.onlyImgPdf} onChange={(e) => changeSetting('onlyImgPdf', e.target.checked)}/>
                    <label htmlFor={`onlyImgPdf`}>Toon standaard enkel Foto's en PDF's in de OwnCloud map</label>
                </div>
                <div className="setting">
                    <select type="list" id={`maps`} onChange={(e) => changeSetting('maps', e.target.selectedIndex)}>
                        <option value="0" selected={(settings.maps === 0)?true:false}>Google Maps</option>
                        <option value="1" selected={(settings.maps === 1)?true:false}>Waze</option>
                        <option value="2" selected={(settings.maps === 2)?true:false}>Apple Kaarten</option>
                    </select>
                    <label htmlFor={`maps`}>Welke kaarten wil u gebruiken</label>
                </div>
                <div className="setting">
                    <a href={"/login"} onClick={(e) => logout()} className="logout">Log uit</a>
                </div>
            </form>
        </div>
    );
}