import React, {createRef, useContext, useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import FileInput from "./FileInput";
import {AuthContext} from "./AuthContext";

export default function ProjectMailForm(props) {
    const navigate = useNavigate();
    const auth = useContext(AuthContext).auth;
    const project = props.project;
    const [formSubmit, setFormSubmit] = useState(false);
    const [textArea, setTextArea] = useState("");
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const fileInput = createRef();
    const [error, setError] = useState("");

    function handleSubmit (event) {
        event.preventDefault();
        setFormSubmit(true);
    }

    useEffect(() => {
        if (formSubmit) {
            setLoading(true);
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`files[${i}]`, files[i]);
                // formData.append(`filess[${i}]`, fileInput.current.files[i]);
            }
            formData.append('text_html', composeHTMLMail());
            formData.append('text_plain', composePlainMail());
            formData.append('subject', `Project ${project.id} - ${project.projName} aanpassen`);

            axios({
                method: "post",
                url: "https://technician.verbo.be/sendmail.php",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(data => {
                    console.log(data);
                    if (data.data.error == undefined) {
                        setError("");
                        navigate(`/project/${project.id}`);
                    } else {
                        setError(data.data.error);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setError(error.toString());
                })
                .finally(() => {
                    setLoading(false);
                    setFormSubmit(false);
                });
        }
    }, [formSubmit]);

    function composeHTMLMail() {
        let html = "";

        html += `<h1>Toevoegingen aan <a href="https://verbotech.verbo.be/project/${project.id}">${project.id} - ${project.descr}</a></h1>`;
        html += `<div>Klant: ${project.relName}</div>`;
        html += `<div>OwnCloud map: U:\\${project.param1}</div><hr/>`;

        if (auth.user !== '') {
            html += `<div>Verbotist: ${auth.user}</div>`;
        }
        if (textArea !== '') {
            html += `<div>Algemene opmerking: ${textArea}</div>`;
        }

        return `<div>${html}</div>`;
    }
    
    function composePlainMail() {
        let plain = `Aanpassen aan ${project.id} - ${project.projName}\r\nKlant: ${project.rel_name}`;

        if (auth.user !== '') {
            plain += `Verbotist: ${auth.user}\r\n`;
        }
        if (textArea !== '') {
            plain += `Algemene opmerking: ${textArea}\r\n`;
        }

        return `${plain}`;
    }

    function handleFileChange(e) {
        var tempFiles = e.target.files;
        var filesArr = Array.prototype.slice.call(tempFiles);
        setFiles([...files, ...filesArr]);
    }

    function removeFile(f) {
        setFiles(files.filter(x => x !== f) );
    }

    if (loading) {
        return (
            <div className="loading">Laden...</div>
        );
    }

    return (<>
        {(loading === false) && (
        <div className={`mail-form`}>
            <form onSubmit={handleSubmit}>
                {error !== "" &&
                    <div className="error">Er is iets mis gegaan. {error}</div>
                }
                <textarea value={textArea} onChange={(e) => setTextArea(e.target.value)}
                          placeholder="Algemene informatie"/>
                {/*<label htmlFor="fileUpload">Foto's meesturen?</label>*/}
                {/*<input id="fileUpload" type="file" accept="image/*" ref={fileInput} multiple/>*/}
                <FileInput files={files} onFilesChange={handleFileChange} onFilesRemove={removeFile} />
                <input type="submit" value="Versturen"/>
            </form>
        </div>
        )}</>
    );
};