import React from "react";
import {DateConvert} from "../helpers";
import InstallatieOverzicht from "./InstallatieOverzicht";
import ProjectOverzicht from "./ProjectOverzicht";

export default function KlantProjecten(props) {
    const projecten = props.projecten;
    const verboUser = props.verboUser;

    return (
        <div className="projecten">
            {projecten && projecten.map(project => {
                return (<ProjectOverzicht key={project.id} details={project} verboUser={verboUser} />)
            })}
        </div>
    );
}