import React from "react";
import {isNull} from "util";
import {Link} from "react-router-dom";

export default function KlantInfo (props) {
    const klant = props.klant;

    return (
        <div className="klantInfo">
            <div className="adressen">
                <h3>Leveradressen</h3>
                {klant.projAdresses && klant.projAdresses.map((projAdress, index) => {
                    return (
                        <div className="leveradres" key={index}>
                            <h4>{projAdress.name}</h4>
                            <div>{projAdress.street}</div>
                            <div>{projAdress.zipcode} {projAdress.city}</div>
                        </div>
                    );
                })}
            </div>
            <div className={`contactInfo`}>
                <h3>Contactpersonen</h3>
                {klant.contacten && klant.contacten.map((contact, index) => {
                    return (
                        <div className="contact" key={index}>
                            <div className="naam">{contact.name !== "0" && contact.name}</div>
                            <div className="tel"><a href={`tel:${contact.mobile}`}>{contact.mobile}</a></div>
                            <div className="mail"><a href={`mailto:${contact.email}`}>{contact.email}</a></div>
                        </div>
                    );
                })}
            </div>
            <div className="lekverliezen">
                {klant.lekverliezen && klant.lekverliezen.length !==0 && <h3>Lekverliezen</h3>}
                {klant.lekverliezen && klant.lekverliezen.map(lekverlies => {
                    return (
                        <div className="lekverlies" key={lekverlies.YEAR}>{lekverlies.YEAR}: {Math.round((parseFloat(lekverlies.somlekverlies) + Number.EPSILON) * 100) / 100}kg</div>
                    );
                })}
            </div>
        </div>
    );
}