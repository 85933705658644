import React from "react";
import {DateConvert} from "../helpers";

export default function InstallatieTicketten(props) {
    const ticketten = props.tickettenlijst;
    const klant = props.klant;
    const installatieNummer = props.installatieNummer;

    if (ticketten === null) {
        return (
            <div className="ticketten-container">
                <h2>Geen ticketten gevonden</h2>
            </div>
        );
    }

    return (
        <div className={'ticketten-container'}>
            {
                ticketten.map(ticket => {
                    let classes = 'ticket';
                    if (installatieNummer !== ticket.maintenance_id) classes = classes + ' klant';
                    return(
                    <div key={ticket.id} className={classes}>
                        <h4>{ticket.id}</h4>
                        {klant && <h5><a href={`/installatie/${ticket.maintenance_id}/ticketten`}>{ticket.maintenance_id} - {ticket.contract_type}</a></h5>}
                        <div className="ticket-date">{DateConvert(ticket.date)}</div>
                        <div className="inhoud">{ticket.workdescrTxt}</div>
                    </div>
                );})
            }
        </div>
    );
}