import React, {useContext, useEffect, useRef, useState} from "react";
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function Tickettenlijst (props) {
    const gridRef = useRef();

    const [columnDefs, setColumnDefs] = useState([
        { field: 'id', headerName: 'Nummer', sortable: true },
        { field: 'name', headerName: 'Klant', sortable: true },
        { field: 'aangepast', headerName: 'Laatst aangepast', sortable: true },
        { field: 'resource_1', headerName: 'Aangemaakt door' },
        { field: 'workdescrTxt', headerName: 'Omschrijving', minWidth: 600 }
    ]);

    return (
        <div className="tickettenlijst">
            <div className="ag-theme-alpine" style={{ height: 'unset', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    domLayout={'autoHeight'}
                    rowData={props.ticketten}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
            {/*{props.ticketten && props.ticketten.map(ticket => {
                return (
                    <div key={ticket.id} className="ticket">
                        {ticket.id}
                    </div>
                );
            })}*/}
        </div>
    );
}

export default Tickettenlijst;