import React, {createContext, useEffect, useState} from "react";
const AuthContext = createContext();

function AuthProvider (props) {
    const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));

    function changeAuth(respJSON) {
        const resp = JSON.parse(respJSON);

        if (resp.error === null) {
            setAuth(null);
        } else {
            setAuth(resp);
        }
    }

    const value = {
        auth: auth,
        setAuth: setAuth
    };

    return (
        <AuthContext.Provider value={value}>
            {props.children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider};