
function DataParser (data) {
    let innerData;

    if (data.payload === undefined) {
        innerData = data;
    } else {
        innerData = data.payload;
    }

    return innerData;
}

export default DataParser;