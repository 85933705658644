import React, {createContext, useEffect, useState} from "react";

const SettingsContext = createContext();

function SettingsProvider(props) {
    const [settings, setSettings] = useState(JSON.parse(localStorage.getItem("settings")));

    function changeSettings (newSettings) {
        setSettings(newSettings);
        localStorage.setItem("settings", JSON.stringify(newSettings));
    }

    useEffect(() => {
        if (settings == null) {
            changeSettings({
                hideDWG: false,
                onlyImgPdf: true,
                maps: 0
            });
        }
    }, []);

    const value = {
        settings:  settings,
        setSettings: changeSettings
    }

    return (
        <SettingsContext.Provider value={value}>
            {props.children}
        </SettingsContext.Provider>
    );
}

export {SettingsContext, SettingsProvider};