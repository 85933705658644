import React, {useState} from "react";
import {DateConvert} from "../helpers";
import axios from "axios";

export default function InstallatieWerkbonnen(props) {
    const [loadingDoc, setLoadingDoc] = useState(false);

    function getMaterialDerc (material) {
        if (material.refrigerant_fl_refrigerant == 1) {
            let reden = "";
            if (material.refrigerant_fl_destruction == 1) reden = "Vernietiging";
            if (material.refrigerant_fl_firstfill == 1) reden = "Eerste vulling";
            if (material.refrigerant_fl_leakage == 1) reden = "Lekverlies";
            if (material.refrigerant_fl_prefill == 1) reden = "Voorvulling";
            if (material.refrigerant_fl_transfer == 1) reden = "Transfer";

            return material.descr + " - " + reden;
        }

        return material.descr;
    }

    function downloadWerkbon (docID){
        if (loadingDoc) return null;

        setLoadingDoc(true);

        let formData = new FormData();
        formData.append('action', 'download');
        formData.append('werkbon', docID);
        formData.append('pass', props.pass);

        var docURL = "";

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                // base64 string
                let base64str = data.data;

// decode base64 string, remove space for IE compatibility
                let binary = atob(base64str.replace(/\s/g, ''));
                let len = binary.length;
                let buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

// create the blob object with content-type "application/pdf"
                let blob = new Blob( [view], { type: "application/pdf" });
                let url = URL.createObjectURL(blob);
                window.open(url, '_self');

            })
            .catch(error => console.log(error))
            .finally(() => setLoadingDoc(false));

        return docURL;
    }

    //TODO: Ventilatieverslag nog juist zetten
    let certificaten = {
        V51: " OC", // Onderhoud
        V52: " DC", // Druktest
        V53: " RC", // Recuperatie
        V54: " LC", // lektest
        V55: " ", // Ventilatie verslag
        V56: " OR", // Opstartrapport
        V57: " VC" // Ventilatieonderhoud
    };

    return (
        <div className="werkbonnen">
            {props.werkbonnen && props.werkbonnen.map(werkbon => {return (
                <div key={werkbon.id} className="werkbon">
                    <h4>{DateConvert(werkbon.date)}</h4>
                    <div className="person">{werkbon.resource_2}</div>
                    <div className="id">{werkbon.id}
                        <div className="downloadlink">
                            <a href="#" onClick={(event) => {event.preventDefault();downloadWerkbon(werkbon.id)}}><i className="fa-solid fa-file"></i></a>
                        </div>
                    </div>
                    <h5>Werkomschrijving:</h5>
                    <div className="workdescr">{werkbon.workdescrTxt !== undefined && werkbon.workdescrTxt.replace(/(?:\r\n|\r|\n)/g, '\n').split('\n').map(str => <p>{str}</p>)}</div>
                    <h5>Opmerking:</h5>
                    <div className="opmerkingen">{werkbon.remarkTxt !== undefined && werkbon.remarkTxt.replace(/(?:\r\n|\r|\n)/g, '\n').split('\n').map(str => <p>{str}</p>)}</div>
                    {werkbon.materials && werkbon.materials.length != 0 && <h5>Materialen:</h5>}
                    <div className="materialen">
                        {werkbon.materials && werkbon.materials.map((material, index) => {
                            return (
                                <div key={index} className="materiaal">
                                    <div className="id">{material.call_id}</div>
                                    <div className="omschrijving">{getMaterialDerc(material)}
                                        {(material.call_id in certificaten) && (<div className="downloadlink">
                                            <a href="#" onClick={(event) => {
                                                event.preventDefault();
                                                downloadWerkbon(werkbon.id + certificaten[material.call_id])
                                            }}><i className="fa-solid fa-file"></i></a>
                                        </div>)}
                                    </div>
                                    <div className="aantal">{Math.round((parseFloat(material.quantity) + Number.EPSILON) * 100) / 100} {material.unit}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )})}
        </div>
    );
}