import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import DataParser from "../helpers/DataParser";
import {AuthContext} from "./AuthContext";
import Loading from "./Loading";
import {AgGridReact} from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';

export default function Partners(props) {
    const [partners, setPartners] = useState();
    const [loading, setLoading] = useState(true);
    const pass = useContext(AuthContext).auth.pass;
    const gridRef = useRef();

    const [columnDefs, setColumnDefs] = useState([
        { field: 'klant', headerName: 'Klant', sortable: true },
        { field: 'name', headerName: 'Naam', sortable: true },
        { field: 'email', headerName: 'E-mail', sortable: true },
        { field: 'total_visits', headerName: 'Aantal bezoeken', sortable: true }
    ]);

    /**
     * Cram all columns to fit into the grid initially.
     */
    const onGridReady = params => {
        params.api.sizeColumnsToFit();
    };

    /**
     * Auto-size all columns once the initial data is rendered.
     */
    const autoSizeColumns = params => {
        const colIds = params.columnApi
            .getAllDisplayedColumns()
            .map(col => col.getColId());

        params.columnApi.autoSizeColumns(colIds);
    };

    useEffect(() => {
        if (partners != null) {
            setLoading(false)
        }

        let formData = new FormData();
        formData.append('action', 'partners');
        formData.append('pass', pass);

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                let partnerData = DataParser(data.data);
                setPartners(partnerData);
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false));
    }, []);

    if (loading) return (<Loading />);

    return (
        <main className={`partners`}>
            <h1>Partners</h1>
            <div className="uitleg-partners">Onderstaande personen hebben toegang tot de VerboTech.<br />Dit gebeurt door de optie "opt-in" bij contactpersoon in een relatie is aangeduidt.</div>
            <div className="ag-theme-alpine" style={{ height: 'unset', width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    domLayout={'autoHeight'}
                    rowData={partners}
                    onGridReady={onGridReady}
                    onFirstDataRendered={autoSizeColumns}
                    columnDefs={columnDefs} />
            </div>
        </main>
    );
}