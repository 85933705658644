import React, {useEffect, useState, useRef, useContext} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import InstallatieOverzicht from "./InstallatieOverzicht";
import {AuthContext} from "./AuthContext";
import KlantOverzichtInstallaties from "./KlantOverzichtInstallaties";
import DataParser from "../helpers/DataParser";
import Loading from "./Loading";

function Installaties () {
    const pass = useContext(AuthContext).auth.pass;
    const verboUser = useContext(AuthContext).auth.verboUser;
    const params = useParams();
    const navigate = useNavigate();
    const inputRef = useRef();
    const [installaties, setInstallaties] = useState(() => {
        return JSON.parse(localStorage.getItem("installaties"));
    });
    const [klanten, setKlanten] = useState(() => {
        return JSON.parse(localStorage.getItem("klanten"));
    });
    const [zoekwaarde, setZoekwaarde] = useState(() => {
        if (params.zoekwaarde === undefined) return "";
        return params.zoekwaarde
    });
    const [networkLoaded, setNetworkLoaded] = useState(false);
    const [networkKlantenLoaded, setNetworkKlantenLoaded] = useState(false);

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'installaties');
        formData.append('pass', pass);

        inputRef.current.focus();

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                let installatiesData = DataParser(data.data);
                setInstallaties(installatiesData);
                localStorage.setItem("installaties", JSON.stringify(installatiesData));
                setInstallaties(installatiesData);
                setNetworkLoaded(true);
            })
            .catch(error => console.log(error))
            .finally(() => {
                //setInstallaties(() => setFilterWaarde());
            });
    }, []);

    useEffect(() => {
        const filter = zoekwaarde;

        if (filter !== undefined) navigate(`/installaties/${filter}`, { replace: true });

        setInstallaties(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [zoekwaarde]);

    useEffect(() => {
        setInstallaties(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [networkLoaded]);

    useEffect(() => {
        setInstallaties(setFilterWaarde());
        setKlanten(getKlantenMatches());
    }, [networkKlantenLoaded]);

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'klanten');
        formData.append('pass', pass);

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                let klantenData = DataParser(data.data);
                setKlanten(klantenData);
                localStorage.setItem("klanten", JSON.stringify(klantenData));
                setKlanten(klantenData);
                setNetworkKlantenLoaded(true);
            })
            .catch(error => console.log(error))
            .finally(() => {
                //setInstallaties(() => setFilterWaarde());
            });
    }, []);

    function setFilterWaarde(data = installaties) {

        if (!data) return [];

        let _zoekwaarde = zoekwaarde.trim();

        const updatedInstallaties = data.map((installatie) => {
            let filterWaarde = 0;
            installatie.filterWaarde = 0;

            // Volledig zoekveld
            filterWaarde += getSpecifiekeWaarde(installatie.contract_type_txt, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.rel_name, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.rel_street, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.rel_zipcode, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.rel_city, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.projStreet, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.projZipCode, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.projCity, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.projName, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.rel_contactname, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.contact_name, _zoekwaarde, 10);
            filterWaarde += getSpecifiekeWaarde(installatie.id, _zoekwaarde, 10);
            filterWaarde += getComponentenfilterwaarde(installatie.components, _zoekwaarde, 20);

            zoekwaarde.split(" ").forEach(filter => {
                if (filter === "") return;
                filterWaarde += getSpecifiekeWaarde(installatie.contract_type_txt, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.rel_name, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.rel_street, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.rel_zipcode, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.rel_city, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.projStreet, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.projZipCode, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.projCity, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.projName, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.rel_contactname, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.contact_name, filter, 1);
                filterWaarde += getSpecifiekeWaarde(installatie.id, filter, 1);
                filterWaarde += getComponentenfilterwaarde(installatie.components, filter, 2);
            });

            // TelefoonNummer nakijken
            filterWaarde += getTelefoonfilterwaarde(installatie.phones);

            installatie.filterWaarde = filterWaarde;
            return installatie;
        });

        return updatedInstallaties;
    }

    function getKlantenMatches(data = klanten) {
        if (!data) return [];

        const updatedklanten = data.map((klant) => {
            let filterWaarde = 0;
            klant.filterWaarde = 0;

            // TelefoonNummer nakijken
            filterWaarde += getTelefoonfilterwaarde(klant.phones);

            klant.filterWaarde = filterWaarde;
            return klant;
        });

        return updatedklanten;
    }

    function getSpecifiekeWaarde(zoekveld, waarde, multiplier = 1) {
        if (waarde === "") return 0;

        if (zoekveld && zoekveld.toString().toLowerCase() === waarde.toString().toLowerCase()) {
            return 10 * multiplier;
        } else if (zoekveld && zoekveld.toString().toLowerCase().startsWith(waarde.toString().toLowerCase())) {
            return 5 * multiplier;
        } else if (zoekveld && zoekveld.toString().toLowerCase().includes(waarde.toString().toLowerCase())) {
            return 1 * multiplier;
        }
        return 0;
    }

    function getTelefoonfilterwaarde(phones) {
        const regex = /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(56|60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/;
        const zoekTelefoonRegEx = regex.exec(zoekwaarde.replaceAll(" ",""));
        if (zoekTelefoonRegEx === null) return 0;

        let tempZoekwaarde = 0;

        Object.values(phones).forEach(phone => {
            const tempPhone = regex.exec(phone);
            if (tempPhone !== null && tempPhone[4] !== null && tempPhone[4] === zoekTelefoonRegEx[4]) tempZoekwaarde = 10000;
        })

        return tempZoekwaarde;
    }

    function getComponentenfilterwaarde(componenten, waarde, multiplier = 1) {
        let _filterwaarde = 0;

        if (componenten === undefined || componenten.length == null) return 0;

        Object.values(componenten).forEach(component => {
            Object.values(component).forEach(componentveld => {
                _filterwaarde += getSpecifiekeWaarde(componentveld, waarde, multiplier);
            });
        });

        return _filterwaarde;
    }

    function filterFunctie (installatie) {
        if (installaties != null && installaties.length < 150) return true;
        if (zoekwaarde != null && zoekwaarde.length < 3) {
            return false;
        }
        return (installatie.filterWaarde > 0);
    }

    function filterFunctieKlanten (klant) {
        if (zoekwaarde != null && zoekwaarde.length < 3) {
            return false;
        }
        return (klant.filterWaarde > 0);
    }

    function getEmptyState() {
        if (installaties != null && installaties.length < 150) return;

        if ((zoekwaarde != null && zoekwaarde.length < 3)) {
            return (
                <div className={`emptystate`}><div>Zoek een installatie door minstens 3 karakters in te vullen</div></div>
            );
        }

        return;
    }

    return (<div className="installaties">
        <input ref={inputRef} id="installatieZoekveld" autoComplete="off" value={zoekwaarde} onChange={event => setZoekwaarde(event.target.value)} placeholder="Zoeken..." />
        <main>
            {(installaties === undefined || installaties === null || installaties.length === 0) && <Loading /> }
            {klanten && verboUser && klanten.filter(filterFunctieKlanten).map(klant => {
                return (<KlantOverzichtInstallaties key={klant.id} details={klant} verboUser={verboUser} />);
            })}
              {installaties && installaties.filter(filterFunctie).sort((a, b) => a.filterWaarde > b.filterWaarde ? -1 : 1).map(installatie => {
                  return (<InstallatieOverzicht key={installatie.id} details={installatie} verboUser={verboUser} />)
              })}
            {getEmptyState()}
        </main>
    </div>);
}

export default Installaties;