import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";

export default function Login () {
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [loginSubmit, setLoginSubmit] = useState(false);
    const [userpass, setUserpass] = useState(false);
    const [validation, setValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [external, setExternal] = useState(false);
    const authC = useContext(AuthContext);
    const navigate = useNavigate();

    function handleSubmitLogin (e) {
        e.preventDefault();
        setExternal(false);
        setLoginSubmit(true);
    }

    function handleSubmitLoginExternal (e) {
        e.preventDefault();
        setExternal(true);
        setLoginSubmit(true);
    }

    useEffect(() => {
        if (loginSubmit) {
            setLoading(true);
            let formData = new FormData();
            if (external) {
                formData.append('external', external);
                formData.append('email', email);
            } else {
                formData.append('username', username);
                formData.append('userpass', userpass);
            }

            axios({
                method: "post",
                url: "https://technician.verbo.be/ldap.php",
                data: formData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(data => {
                    setValidation(false);
                    if (data.data.error === undefined) {
                        if (external) {
                            navigate("/login/confirm");
                            return;
                        }
                        if (data.data.OwnCloud != null && data.data.OwnCloud.length != 0) {
                            data.data.verboUser = true
                        } else {
                            data.data.verboUser = false;
                        }
                        localStorage.setItem("auth", JSON.stringify(data.data));
                        authC.setAuth(data.data);
                        navigate("/");
                    } else  {
                        setValidation(true);
                        authC.setAuth(null);
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loginSubmit]);

    useEffect(() => {
        if (loading === false)
            setLoginSubmit(false);
    }, [loading]);

    return (
        <>
            {loading !== true && <div className="usertype-container">
                <div className="login-container externalist">
                    <div className="login">
                        <h2>Partners</h2>
                        <form action="#" className="loginform" onSubmit={handleSubmitLoginExternal}>
                            <input type="email" className="username" placeholder="E-mail"
                                   onChange={e => setEmail(e.target.value)}/>
                            {validation && external && <div className="validation">E-mailadres niet terug gevonden. Neem contact op met Verbo.</div>}
                            <input type="submit" className="submit" value="Login"/>
                        </form>
                    </div>
                </div>
                <div className="login-container verbotist">
                    <div className="login">
                        <h2>Verbo werknemers</h2>
                        <form action="#" className="loginform" onSubmit={handleSubmitLogin}>
                            <input type="text" className="username" placeholder="Gebruiker"
                                   onChange={e => setUsername(e.target.value)}/>
                            <input type="password" className="password" placeholder="Wachtwoord"
                                   onChange={e => setUserpass(e.target.value)}/>
                            {validation && external!==true && <div className="validation">Gebruikersnaam en/of wachtwoord kloppen niet</div>}
                            <input type="submit" className="submit" value="Login"/>
                        </form>
                    </div>
                </div>
            </div>}
            {loading && <Loading />}
        </>
    );
}