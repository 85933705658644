import React, {useState} from "react";
import Directory from "./Directory";

export default function InstallatieDossier (props) {
    const pad = props.pad.replaceAll('\\','/');

    return (
        <div className="owncloud-wrapper">
            <Directory pad={pad} title={pad} owncloud={false} extensions={[]} />
        </div>
    );
}