import React, {useContext, useState} from "react";
import axios from "axios";
import Loading from "./Loading";
import {AuthContext} from "./AuthContext";

export default function File (props) {
    const [loadingDoc, setLoadingDoc] = useState(false);
    const pass = useContext(AuthContext).auth.pass;
    const owncloudDossier = props.owncloud;
    const imgHandler = props.imgHandler;

    function downloadDoc (doc){
        if (loadingDoc) return null;

        setLoadingDoc(true);

        if (doc.indexOf("/Verbo/") === 0) doc = doc.substring(7);

        let formData = new FormData();
        formData.append('action', 'owncloud');
        formData.append('doc', doc);
        formData.append('owncloud', (owncloudDossier)?1:0);
        formData.append('pass', pass);

        var docURL = "";

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                // base64 string
                let base64str = data.data.encodedString;
                let type = data.data.typeString;

// decode base64 string, remove space for IE compatibility
                let binary = atob(base64str.replace(/\s/g, ''));
                let len = binary.length;
                let buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }

// create the blob object with content-type "application/pdf"
                let blob = new Blob( [view], { type: type });
                let url = URL.createObjectURL(blob);
                // window.open(url, '_self');
                if (type.includes("image")) {
                    imgHandler(url);
                } else {
                    let elm = document.createElement('a');
                    elm.href = url;
                    let filename = doc.substring(doc.lastIndexOf("/"));
                    elm.setAttribute('download', filename); // SET ELEMENT CREATED 'ATTRIBUTE' TO DOWNLOAD, FILENAME PARAM AUTOMATICALLY
                    elm.click();
                }
            })
            .catch(error => console.log(error))
            .finally(() => setLoadingDoc(false));

        return docURL;
    }

    return (
        <div key={props.index} className="file"><a className="file-link" href="#" onClick={(event) => {
            event.preventDefault();
            downloadDoc(props.file.filename);
        }}><i
            className="fa-solid fa-file"></i> {props.file.basename}{loadingDoc && <Loading />}</a></div>
    );
}
