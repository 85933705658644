import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "./AuthContext";
import axios from "axios";
import Tickettenlijst from "./Tickettenlijst";

function TickettenUser (props) {
    const pass = useContext(AuthContext).auth.pass;
    const user = useContext(AuthContext).auth.user;
    const [networkLoaded, setNetworkLoaded] = useState(false);
    const [tickettenUser, setTickettenUser] = useState(() => {
        return JSON.parse(localStorage.getItem("tickettenUser"));
    });

    useEffect(() => {
        let formData = new FormData();
        formData.append('action', 'tickettenuser');
        formData.append('user', user);
        formData.append('pass', pass);

        axios({
            method: "post",
            url: "https://technician.verbo.be/cafca.php",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(data => {
                setTickettenUser(data.data);
                localStorage.setItem("tickettenUser", JSON.stringify(data.data));
                setTickettenUser(data.data);
                setNetworkLoaded(true);
            })
            .catch(error => console.log(error))
            .finally(() => {
                //setInstallaties(() => setFilterWaarde());
            });
    }, []);

    return (
        <div className="tickettenUser">
            <h2>Mijn ticketten</h2>
            <Tickettenlijst ticketten={tickettenUser} />
        </div>
    );
}

export default TickettenUser;